// console.log('hello world')
const content = document.getElementById("content")
const loading = document.getElementById("loading")
const iframe = document.createElement('iframe');

const source = 'https://docs.google.com/document/d/e/2PACX-1vSCTCw5nEeInVD23vOiVr2uLLU3kPFPUpBPktKFStfqDmhzswwhlBNxh359sUpWUG8ctGx7Gmt1ZWuu/pub?embedded=true'

$.get(source, (data, status) => {
  $('iframe').contents().find('head').append('<base target="_blank">')
  const modData = data.replace(
    /href="(https\:\/\/www\.google\.com\/url\?q\=)(.*?)(\&)([^\'\"]+)(\")/g,
    (match, p1, p2) => {
      return `href="${p2}"`
    }
  )
  $('iframe').contents().find("body").append(modData)
  $('#loading').hide()
  $('#content').css('display', 'flex')
})
// iframe.style.display = 'none'
// iframe.onload = function() {
//   iframe.style.width = '100%'
//   iframe.style.height = '100%'
//   loading.style.display = 'none';
//   iframe.style.display = 'flex'
//   $.get('a.html', null, function(text){
//     alert($(text).find('#name'));
// });
  // iframe.getElementByName('head')[0].appendChild('<base target="_blank">')
// }
// iframe.name = 'content-iframe'
// content.appendChild(iframe);
